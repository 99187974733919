<template>
  <div class="row m-0 align-items-center">
    <div class="col-12 p-0">
      <div class="row m-0 align-items-center justify-content-center w-100 form-control_holder" :class="[color, size, { error: hasErrors > 0}]">
        <div class="col-auto pe-0 ps-1" :class="[color, size]">
          <icon-minus @click="decrement" :color="[color, {black: color === 'goldgold'}]" size="size16" class="cursor" />
        </div>
        <div class="col p-0">
          <input
          class="form-control medium"
          :class="[color, size]"
          :id="id"
          :placeholder="placeholder"
          :autocomplete="autocomplete"
          :disabled="disabled"
          type="number"
          min="0"
          v-model="value" >
        </div>
        <div class="col-auto ps-0 pe-1" :class="[color, size]">
          <icon-plus :color="[color, {black: color === 'goldgold'}]" @click="increment" size="size16"  class="cursor"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    IconPlus: defineAsyncComponent(() => import('./icons/IconPlus.vue')),
    IconMinus: defineAsyncComponent(() => import('./icons/IconMinus.vue'))
  },
  name: 'TextField',
  props:
     {
       id: {
         type: String,
         default: Math.random().toString(20).substr(2, 6)
       },
       placeholder: String,
       label: String,
       modelValue: Number,
       size: String,
       color: String,
       errors: Array,
       hasErrors: Number,
       autocomplete: String,
       disabled: Boolean
     },
  emits: ['update:modelValue'],
  computed: {
    value: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  watch: {
    value (val) {
      if (val < 0) {
        this.value = 0
      }
    }
  },
  methods: {
    increment () {
      this.value++
    },
    decrement () {
      if (this.value > 0) {
        this.value--
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .form-control_holder {
    background-color: var(--green-color-dark);
    color: var(--green-color-light);
    border-radius: 2rem;
    border: 2px solid var(--green-color-dark);
    font-size: 1rem;
    height: calc(2.5rem + 4px);
    font-weight: 500;
    text-align: center;
    display: inline-flex;
  }
  .form-control {
    background-color: transparent;
    border-radius: 0;
    border: none;
    font-size: inherit;
    height: 100%;
    font-weight: inherit;
    text-align: center;
    padding: 0;
    color: inherit;
  }
  .form-control_holder:disabled {
    background-color: var(--green-color-dark);
    opacity: 0.9;
    cursor: not-allowed;
  }
  .form-control_holder.gold {
    color: var(--gold-color);
  }
  .form-control_holder.goldgold {
    background-color: var(--gold-color);
    color: #000;
    border: 2px solid var(--gold-color);
  }
  .form-control_holder.white {
    color: #fff;
  }
  .form-control_holder:focus-within {
    background-color: var(--green-color-dark);
    color: var(--green-color-light);
    box-shadow: none;
    border: 2px solid var(--green-color-light);;
  }
  .form-control_holder.gold:focus-within {
    color: var(--gold-color);
  }
  .form-control_holder.goldgold:focus-within {
    background-color: var(--gold-color);
    color: #000;
    border: 2px solid var(--gold-color);
  }
  .form-control_holder.white:focus-within {
    color: #fff;
  }
  .form-control:focus {
    border: none;
    outline: none;
    box-shadow: none;
    color: inherit;
    background-color: transparent;
  }
  .big {
    height: 4.5rem;
    border-radius: 4rem;
    font-size: 2.5rem;
    text-align: center;
    color: var(--gold-color);
  }
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--green-color-light) !important;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--green-color-light) !important;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--green-color-light) !important;
  }
  .goldgold::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #000 !important;
    opacity: 1; /* Firefox */
  }

  .goldgold:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #000 !important;
  }

  .goldgold::-ms-input-placeholder { /* Microsoft Edge */
    color: #000 !important;
  }
  .big::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--gold-color) !important;
    opacity: 1; /* Firefox */
  }
  .big:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--gold-color) !important;
  }
  .big::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--gold-color) !important;
  }
  .uppercase {
    text-transform: capitalize;
  }
  .cursor {
    border-radius: 50%;
    padding: 5px;
  }
  .cursor:hover {
    background-color: rgba(255, 255, 255, 0.7);
  }
</style>
